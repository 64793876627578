import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

//
import logo from "../../images/logo194.png";
import logotext from "../../images/logo-text.png";
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login-bamboo.jpg";
import { loginUser } from "../../redux";

function Login(props: any) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  function onLogin(e: any) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    //dispatch(loginAction(email, password, props.history));
    dispatch(loginUser(email, password, 0, 0, props.history));
  }

  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: "url(" + loginbg + ")" }}
    >
      <div className="login-wrapper">
        {/* <div className="login-aside-left" style={{backgroundImage:"url("+ login +")"}}>
                  <div className="row m-0 justify-content-center h-100 align-items-center">
                        <Link to="/dashboard" className="login-logo-custom">
                          <img src={logo} className="custom-logo-image" alt="" />
                        </Link>
                      <div className="login-description">
                          <ul className="social-icons mt-4">
                          </ul>
                          <div className="mt-5">
                          </div>
                      </div>
                  </div>
                </div> */}
        <div className="">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-9 col-xxl-10">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mt-4 logo-align">
                        <Link to="/dashboard" className="login-logo-custom">
                          <img
                            src={logo}
                            className="mb-4 custom-logo-image"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="mb-4 text-align">
                        <h3 className="text-white mb-1">Welcome!</h3>
                      </div>
                      <div className="mb-4 text-align">
                        <p className="text-white">
                          Sign in by entering information below
                        </p>
                      </div>

                      {props.errorMessage && (
                        <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                          {props.successMessage}
                        </div>
                      )}
                      <form onSubmit={onLogin}>
                        <div className="form-group">
                          <label className="mb-2 ">
                            <strong className="text-white">Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && (
                            <div className="text-danger fs-12">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="mb-2 ">
                            <strong className="text-white">Password</strong>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {errors.password && (
                            <div className="text-danger fs-12">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                          <div className="form-group">
                            <div className="custom-control custom-checkbox ml-1 ">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="basic_checkbox_1"
                              />
                              <label
                                className="form-check-label text-white"
                                htmlFor="basic_checkbox_1 "
                              >
                                Remember my preference
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn bg-white text-primary btn-block"
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                      {/* <div className="new-account mt-2">
                                  <p className="text-white">
                                    Don't have an account?{" "}
                                    <Link className="text-white" to="./page-register">
                                      Sign up
                                    </Link>
                                  </p>
                                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {};
};
export default connect(mapStateToProps)(Login);
