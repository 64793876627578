import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import Select from "react-select";
import {
  EditModGroupProps,
  EditModGroupState,
} from "../../../interface/newMenu";
import { secretKey } from "../../../../secret";
import {
  editModGroup,
  getModGroup,
  groupsListForMultiSelect,
  itemsListForMultiSelect,
  logoutUser,
} from "../../../../redux";
import { Nav, Tab } from "react-bootstrap";
import { EditStampProps, EditStampState } from "../../../interface/stamp";
import { editStamp, getStamp } from "../../../../redux/actions/menuAction";

class EditStamp extends Component<EditStampProps, EditStampState> {
  constructor(props: any) {
    super(props);
    this.state = {
      stamp_title: "",
      stamp_description: "",
      itemsJson: {},
      stamp_limit: "",
      groupJson: {},
      level: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleStampLevel = this.handleStampLevel.bind(this);
  }
  componentDidMount() {
    let id = this.props.id;
    this.props.getStamp(id);
    this.props.groupsList();
    this.props.itemsListForMultiSelect();
    document.title = "Update Stamp";
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      stamp_title: nextProps.stampData.stamp_title,
      stamp_description: nextProps.stampData.stamp_description,
      stamp_limit: nextProps.stampData.stamp_limit,
      level: nextProps.stampData.type,
      itemsJson: nextProps.stampData.product,
      groupJson: nextProps.stampData.category,
    });
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  isStampReady = () => {
    const { stamp_description, stamp_limit, itemsJson, groupJson } = this.state;
    if (itemsJson) {
      return stamp_description !== "" && stamp_limit > 0 && itemsJson;
    }
    if (groupJson) {
      return stamp_description !== "" && stamp_limit > 0 && groupJson;
    }
  };

  handleItemsInputChange = (e: any) => {
    if (e) {
      this.setState({ itemsJson: e });
    }
  };
  handleSubmit = (event: any) => {
    let {
      stamp_description,
      stamp_limit,
      itemsJson,
      groupJson,
      level,
      stamp_title,
    } = this.state;
    let id = this.props.id;
    let data: any = {
      stamp_description: stamp_description,
      stamp_title: stamp_title,
      stamp_limit: stamp_limit,
      type: level,
    };
    if (JSON.stringify(itemsJson) !== "{}") {
      data["product"] = JSON.stringify(itemsJson);
    }
    if (JSON.stringify(groupJson) !== "{}") {
      data["category"] = JSON.stringify(groupJson);
    }
    this.props.editStamp(id, data);
    event.preventDefault();
  };
  handleGroupsInputChange = (e: any, index: any) => {
    if (e) {
      this.setState({ itemsJson: {}, groupJson: e });
    }
  };
  handleStampLevel(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    let { stamp_description, stamp_limit, itemsJson, groupJson, stamp_title } =
      this.state;
    const { isUpdated, message, stampData, items, groups } = this.props;
    if (isUpdated) {
      msg = message;
      messcolor = "green";
    } else {
      msg = message;
      messcolor = "red";
    }
    return (
      <>
        {this.props.id && (
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label className="form-control-label">
                  Stamp Title <span className="text-danger">*</span>
                </label>
                <input
                  id="stamp_title"
                  type="text"
                  maxLength={20}
                  name="stamp_title"
                  value={stamp_title}
                  required
                  data-msg="Please enter stamp title"
                  className="form-control"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="col-5">
              <div className="form-group">
                <label className="form-control-label">
                  Stamp Description <span className="text-danger">*</span>
                </label>
                <input
                  id="stamp_description"
                  type="text"
                  maxLength={40}
                  name="stamp_description"
                  value={stamp_description}
                  required
                  data-msg="Please enter Modifier Name"
                  className="form-control"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label className="form-control-label">Stamp Limit</label>
                <input
                  id="stamp_limit"
                  type="number"
                  name="stamp_limit"
                  value={stamp_limit}
                  required
                  data-msg="Please enter Priority"
                  className="form-control"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>
        )}
        {this.props.id && (
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-control-label">
                  Stamp Level <span className="text-danger">*</span>
                </label>
                <select
                  name="level"
                  className="form-control text-capitalize mt-2"
                  required
                  data-msg="Please select Type"
                  onChange={this.handleStampLevel}
                  disabled
                >
                  <option
                    value="group"
                    {...(stampData.type == "group" && { selected: true })}
                  >
                    Category
                  </option>
                  {/* <option value='item' {...stampData.type == 'item' && { selected: true }}>Product</option> */}
                </select>
              </div>
            </div>

            {this.state.level === "group" && (
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">
                    Select Category <span className="text-danger">*</span>
                  </label>
                  <Select
                    // isMulti
                    name="groups"
                    value={groupJson}
                    options={groups}
                    className="text-capitalize basic-multi-select mt-2"
                    classNamePrefix="select"
                    onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                  />
                </div>
              </div>
            )}
            {this.state.level === "item" && (
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">
                    Select Product <span className="text-danger">*</span>
                  </label>
                  <Select
                    // isMulti
                    name="items"
                    options={items}
                    value={itemsJson}
                    className="text-capitalize basic-multi-select mt-2"
                    classNamePrefix="select"
                    onChange={(e, i) => this.handleItemsInputChange(e)}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {this.props.id && (
          <div className="form-group d-flex justify-content-end mt-4">
            <button
              onClick={this.handleSubmit}
              disabled={!this.isStampReady()}
              className="btn btn-primary"
            >
              Update Stamp
            </button>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    items: state.menu.allActiveItems,
    message: state.menu.message,
    isUpdated: state.menu.isUpdated,
    groups: state.menu.groupsptions,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editStamp: function (id: any, data: any) {
      dispatch(editStamp(id, data));
    },
    getStamp: function (id: number) {
      dispatch(getStamp(id));
    },
    itemsListForMultiSelect: function () {
      dispatch(itemsListForMultiSelect());
    },
    groupsList: function () {
      dispatch(groupsListForMultiSelect());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditStamp);
